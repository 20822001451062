import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import TopHero from "../components/tophero"
import RepeatingIcons from "../components/repeatingicons"
import AgencyOverview from "../components/agencyoverview"
import SEO from "../components/seo"
import AlertsSlider from "../components/alertsslider"
import CustomTeaser from "../components/customteaser"
import { Helmet } from 'react-helmet'
import LinkList from '../components/linkslist'
import Banner from '../components/banner'
import Tabs from '../components/tabs'
import ContactTabs from "../components/contacttabs"
import Accordion from "../components/accordion"
import Slider from "../components/slider"
import TabsSection from "../components/tabs-section"


class PageTemplate extends Component {

  componentDidMount() {
    window.seamlessVariables = {url:"https://pwcgov.seamlessdocs.com"};

  }

  render() {
    const landingPage = this.props.data.allNodeLandingPage.edges[0].node
    var groupMenu = this.props.data.allMenuLinkContentMenuLinkContent
    const currentPage = this.props.data.nodePage
    const alerts = this.props.data.allNodeAlert
    const contactPage = this.props.data.allNodeContactPage
    const meta = currentPage.relationships.field_meta_data

    return (
        <Layout >
        <SEO
            title={currentPage.title}
            metaData={meta}
            contentBody={currentPage.body}
            locationOrigin={this.props.location.origin + currentPage.path.alias}
            domen={this.props.location.origin}
        />

        <div className={"site-content sub-landing_page node-"+currentPage.drupal_internal__nid}>

           <AlertsSlider allAlerts={alerts.edges} gruopId={null} globalPage={true} />

          {currentPage.relationships.field_basic_flexible && currentPage.relationships.field_basic_flexible.map((layout,key) =>(
            layout.__typename === 'paragraph__agency_overview' ? (
              <section className="agency-overview" key="paragraph__agency_overview">
                <AgencyOverview content={layout} page={currentPage} orientation="right" />
              </section>
            ) : (
              layout.__typename === 'paragraph__repeating_icon_description_link' ? (
                <section className="login-find-activity-park" key="paragraph__repeating_icon_description_link">
                  <RepeatingIcons content={layout} />
                </section>
              ) : (
                layout.__typename === 'paragraph__overview_extended' ? (
                  <section className="agency-overview" key="paragraph__overview_extended">
                    <AgencyOverview content={layout} page={currentPage} orientation="left" />
                  </section>
                ) : (
                  layout.__typename === "paragraph__custom_teaser" ? (
                    <section className="teaser" key={"paragraph__custom_teaser" + key}>
                      <CustomTeaser content={layout} />
                    </section>
                  ) : (
                    layout.__typename === 'paragraph__wysiwyg' ? (
                      <section className={"paragraph__wysiwyg" + key}>
                        <div className="container">
                          <div dangerouslySetInnerHTML={{ __html: layout.field_description_wysiwyg.processed }} />
                        </div>
                      </section>
                    ) : (
                      layout.__typename === "paragraph__repeating_title_and_repeating_li" ? (
                        <section className="links-list" key={"paragraph__repeating_title_and_repeating_li" + key}>
                          <div className="container">
                            <LinkList content={layout} />
                          </div>
                        </section>
                        ) : (
                          layout.__typename === "paragraph__banner" ? (
                            <section className="banner" key={"paragraph__banner" + key}>
                              <div className="container">
                                <Banner content={layout} />
                              </div>
                            </section>
                          ) : (
                            layout.__typename === "paragraph__tabs_custom_teasers" ? (
                              <section className="landing-tabs" key="paragraph__tabs_custom_teasers">
                                <div className="container">
                                  <Tabs content={layout} />
                                </div>
                              </section>
                            ) : (
                             layout.__typename === "paragraph__tabs_with_contact_teasers" ? (
                                <section className="info-tabs pools" key="paragraph__tabs_with_contact_teasers">
                                  <ContactTabs tabs={layout.relationships.field_tab_with_contact_teasers} />
                                </section>
                              ) : (
                                layout.__typename === 'paragraph__accordion' ? (
                                  <section className="accordion-section" key={"paragraph__accordion"+key}>
                                    <Accordion content={layout} />
                                  </section>
                                ) : (
                                  layout.__typename ==='paragraph__slider' ? (
                                    <section className="slider-section" key={"paragraph__slider"+key}>
                                      <Slider content={layout} />
                                    </section>
                                  ) : (
                                    layout.__typename ==='paragraph__tabs' ? (
                                      <section className="tabs-section" key={"paragraph__tabs"+key}>
                                        <TabsSection content={layout} />
                                      </section>
                                    ) : (
                                      null
                                    )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          ))}

          <section className="body-content">
            <div className="container">

              <h2>{currentPage.title}</h2>

              {currentPage.body && currentPage.body ? (

                <div dangerouslySetInnerHTML={{ __html: currentPage.body.processed }} />

              ) : (
                null
              )}

            </div>
          </section>

          {currentPage.field_description_wysiwyg !== null ? (
            <section>
              <Helmet>
                <script async type="text/javascript" src="https://pwcgov.seamlessdocs.com/components/odoForms/js/embed-iframe/head.js"></script>
                <script async type="text/javascript" src="https://pwcgov.seamlessdocs.com/components/odoForms/js/embed-iframe/body.js"></script>
              </Helmet>
              <div className="container">
                <div dangerouslySetInnerHTML={{ __html: currentPage.field_description_wysiwyg.value}}/>
              </div>
            </section>
          ) : (
            null
          )}

        </div>
        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!, $groupmenu: String, $landing: Int, $groupid: Int) {

    nodePage(id: { eq: $id }) {
      title
      path {
        alias
      }
      field_description_wysiwyg {
        value
      }
      relationships {
        field_meta_data {
          ...paragraph__meta_dataFragment
        }
        field_basic_flexible {
          ... on paragraph__custom_teaser {
            field_title
            field_description
            field_link {
              title
              uri
            }
            relationships {
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__tabs {
            id
            relationships {
              field_tab_item {
                field_title
                field_description_wysiwyg {
                  processed
                }
              }
            }
          }

          ... on paragraph__slider {
            relationships {
              field_slider_item {
                ... on paragraph__slider_image {
                  id
                  relationships {
                    field_media_image {
                      relationships {
                        field_media_image {
                          uri {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__slider_video {
                  field_title
                }
              }
            }
          }

          ... on paragraph__tabs_with_contact_teasers {
            id
            relationships {
              field_tab_with_contact_teasers {
                field_title
                field_description_wysiwyg {
                  value
                  processed
                }
                relationships {
                  field_pool_info {
                    field_title
                    field_description_wysiwyg {
                      value
                      processed
                    }
                    relationships {
                      field_media_image {
                        relationships {
                          field_media_image {
                            uri {
                              url
                            }
                          }
                        }
                      }
                      field_contact_info {
                        ... on paragraph__contact_address {
                          field_address
                          field_address_line_2
                        }
                        ... on paragraph__contact_custom_text {
                          field_description_wysiwyg {
                            value
                            processed
                          }
                        }
                        ... on paragraph__contact_phone {
                          field_phone
                          field_title
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__accordion {
            field_half_width
            field_title
            field_animate_items
            relationships {
              field_accordion_item {
                field_title
                field_description_wysiwyg {
                  processed
                }
              }
            }
          }

          ... on paragraph__tabs_custom_teasers {
            id
            field_rotation_interval
            relationships {
              field_tab_teaser {
                field_title
                field_tab_title
                field_link {
                  title
                  uri
                }
                field_link_2 {
                  title
                  uri
                }
                field_video_link {
                  title
                  uri
                }
                field_description
                relationships {
                  field_media_image {
                    relationships {
                      field_media_image {
                        uri {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__wysiwyg {
            field_description_wysiwyg {
              value
              processed
            }
          }

          ... on paragraph__banner {
            field_description
            field_title
            field_link {
              uri
              title
            }
            relationships {
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__repeating_icon_description_link {
            id
            relationships {
              field_item {
                field_link {
                  title
                  uri
                }
                field_description
                field_title
                relationships {
                  field_icon {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__repeating_title_and_repeating_li {
            relationships {
              field_links_section {
                field_title
                field_repeating_link {
                  title
                  uri
                }
              }
            }
          }
          ... on paragraph__agency_overview {
            field_title
            field_description
            field_name
            field_position
            field_link {
              title
              uri
            }
            relationships {
              field_contact {
                ... on paragraph__contact_address {
                  field_address
                  field_address_line_2
                }
                ... on paragraph__contact_email {
                  field_email
                }
                ... on paragraph__contact_phone {
                  field_phone
                  field_title
                }
                ... on paragraph__contact_custom_text {
                  field_description_wysiwyg {
                    processed
                  }
                }
                ... on paragraph__contact_fax {
                  field_phone
                }
                ... on paragraph__contact_mobile_phone {
                  field_phone
                }
              }
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__overview_extended {
            field_title
            field_description_wysiwyg {
              value
              processed
            }
            field_name
            field_position
            field_link {
              title
              uri
            }
            relationships {
              field_contact {
                ... on paragraph__contact_address {
                  field_address
                  field_address_line_2
                }
                ... on paragraph__contact_email {
                  field_email
                }
                ... on paragraph__contact_phone {
                  field_phone
                  field_title
                }
              }
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      drupal_internal__nid
      body {
        value
        processed
      }
    }

    allSitePage(filter: {context: {drupal_nid: {ne: null}}}) {
      ...SitePageConnectionFragment
    }

    allNodeAlert(sort: {fields: field_weight, order: ASC}) {
      edges {
        ...node__alertEdgeFragment
      }
    }

    allNodeContactPage(filter: {relationships: {group_content__group_content_type_4f1d2aba752bd: {elemMatch: {relationships: {gid: {drupal_internal__id: {eq: $groupid}}}}}}}) {
      edges {
        node {
          title
          path {
            alias
          }
        }
      }
    }

    allMenuLinkContentMenuLinkContent(filter: {menu_name: {eq: $groupmenu}, enabled: {eq: true}}, sort: {fields: weight, order: ASC}) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          title
          link {
            title
            uri
          }
        }
      }
    }
    allNodeLandingPage(filter: {drupal_internal__nid: {eq: $landing}}) {
      edges {
        ...node__landing_pageEdgeFragment
      }
    }
  }
`
